import { render, staticRenderFns } from "./tit-wrap-quarternary.vue?vue&type=template&id=8348638a&scoped=true&"
import script from "./tit-wrap-quarternary.vue?vue&type=script&lang=js&"
export * from "./tit-wrap-quarternary.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8348638a",
  null
  
)

export default component.exports