<template>
    <btn-hamburger class="btn-hamburger--concave" @click="$emit('click')"><slot /></btn-hamburger>
</template>

<script>
import BtnHamburger from "@/components/publish/styles/buttons/btn-hamburger.vue";

export default {
    props: {},
    components: {
        BtnHamburger,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// .btn-hamburger--concave {
//     ::v-deep{
//         > span {
//             width: 16px;
//             margin-right: 8px;
//         }
//     }
// }
</style>
